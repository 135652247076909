<template>
	<div style="margin: 0;padding: 0;">
		<div class="my-banner">
			<div class="banner-text">
				<h1 style="font-size: xx-large;">关于我们</h1>
				<h4>ABOUT US</h4>
			</div>
			<img crossorigin :src="bannerImage" alt="" class="banner-img" style="width:100%;height:25vw" />
		</div>
		<el-row style="max-width:1200px;margin: auto; margin-top:20px">
			<el-col :span="1"> </el-col>
			<el-col :span="8">
				<el-timeline>
					<el-timeline-item v-for="item in timelineList" :key="item.id" :timestamp="item.timestamp" placement="top">
						<el-card>
							<div v-html="item.text"></div>
						</el-card>
					</el-timeline-item>
				</el-timeline>
			</el-col>
			<el-col :span="14">
				<div style="padding:20px" v-html="history.text"></div>
			</el-col>
			<el-col :span="1"> </el-col>
		</el-row>
	</div>
</template>

<script>
import base from "@/base.js";
import { apiListTimeline } from "@/api/pub/timeline.js";
import { apiGetHistory } from "@/api/pub/history.js";
import bannerImage from "@/assets/images/banner/about_us.jpg";

export default {
	data() {
		return {
			bannerImage: bannerImage,
			timelineList: [],
			history: {
				id: 0,
				text: ""
			}
		};
	},
	methods: {
		//列出时间线
		async listTimeline() {
			try {
				const response = await apiListTimeline();
				//console.log(response);
				if (response.data.code == 200) {
					this.timelineList = response.data.data;
					//console.log(this.timelineList);
				} else {
					this.$message.error(response.data.message);
				}
			} catch (error) {
				console.log(error);
			}
		},
		// 获取关于我们
		async getHistory() {
			try {
				const response = await apiGetHistory();
				//console.log(response);
				if (response.data.code == 200) {
					this.history = response.data.data;
					//console.log(this.history);
				} else {
					this.$message.error(response.data.message);
				}
			} catch (error) {
				console.log(error);
			}
		}
	},
	mounted() {
		//页面渲染完成调用方法获取数据
		this.listTimeline();
		this.getHistory();
	}
};
</script>

<style lang="scss" scoped>
.banner-text {
	margin: 0 auto;
	position: absolute;
	top: 130px;
	left: 200px;
	z-index: 2;
	box-sizing: border-box;
	text-align: left;
	color: #1c69a0;
	background-color: rgba(255, 255, 255, 0.5);
	padding: 8px;
	border-radius: 6px;
}
.my-banner {
	width: 100%;
	height: 400px;
	position: relative;
	z-index: 1;
	overflow: hidden;
}
</style>
